import React from "react";
import "../../LandingPage.css";
import LandingHeader from "../../LandingHeader/LandingHeader";
import LandingFooter from "../../LandingFooter/LandingFooter";

const Blog6 = () => {
  return (
    <>
      <LandingHeader />
      <div
        className="destination-page child-blog-page"
        style={{ maxWidth: "60%" }}
      >
        <h2>
          Guide för upphandling och projektledning av byggprojekt i BRF:er
        </h2>
        <div>
          <h3>Kort sammanfattning</h3>
          <p>
            Att genomföra byggprojekt i en bostadsrättsförening kräver noggrann
            planering och effektiv projektledning. Börja med att kartlägga
            fastighetens renoveringsbehov och prioritera projekten i rätt
            ordning. Finansieringen är en central del – banklån är vanligt och
            kräver ett gediget underlag. För att välja rätt entreprenör är ett
            detaljerat förfrågningsunderlag avgörande, följt av en noggrann
            granskning av minst tre anbud. Använd alltid entreprenadkontrakt
            enligt branschstandard och tydliggör ansvarsfördelningen, där en
            huvudansvarig har överblick över juridik, ekonomi, byggteknik och
            kommunikation.
          </p>
          <p>
            En genomtänkt kommunikationsstrategi håller medlemmar informerade
            och minimerar störningar. Noggrann projektering reducerar risken för
            kostsamma tilläggsarbeten (ÄTA). Avsluta alltid projektet med en
            oberoende slutbesiktning för att säkerställa kvalitet och
            överensstämmelse med avtalet. Genom att följa dessa steg skapar ni
            optimala förutsättningar för framgångsrika byggprojekt och
            långsiktigt hållbara lösningar för föreningen.
          </p>

          <h3>1. Skapa en helhetsbild av fastighetens behov</h3>
          <p>
            Börja med att kartlägga fastighetens eller fastighetsbeståndets
            samtliga renoveringsbehov. Identifiera hur olika projekt hänger
            samman och vilken ordning som är mest kostnadseffektiv. Exempelvis
            bör ett stambyte genomföras före badrumsrenoveringar, och
            dräneringsarbeten före asfaltering av markytor. En genomtänkt
            prioriteringsordning sparar både tid och pengar.
          </p>
          <h3>2. Planera finansieringen</h3>
          <p>
            Finansieringen är grundläggande för alla byggprojekt.
            Bostadsrättsföreningar tar vanligtvis banklån för större
            renoveringar. För att få fördelaktiga lånevillkor behöver ni
            presentera ett välarbetat underlag för banken som tydligt beskriver:
          </p>
          <ul>
            <li>Projektets omfattning och syfte</li>
            <li>Detaljerade kostnadsberäkningar</li>
            <li>Tidplan för genomförande</li>
            <li>Förväntade resultat och effekter</li>
          </ul>
          <p>
            En strukturerad finansieringsplan ökar chanserna för både godkänt
            lån och förmånliga villkor.
          </p>
          <h3>3. Ta fram ett tydligt förfrågningsunderlag</h3>
          <p>
            Ett väl utformat förfrågningsunderlag är avgörande för att attrahera
            kompetenta entreprenörer och få jämförbara anbud. Underlaget bör
            innehålla:
          </p>
          <ul>
            <li>
              Detaljerad beskrivning av uppdragets omfattning och delmoment
            </li>
            <li>Krav på teknisk standard och förväntat resultat</li>
            <li>Realistisk tidsplan med delmål och slutdatum</li>
            <li>Administrativa föreskrifter enligt branschstandard</li>
            <li>Krav på dokumentation och garantier</li>
          </ul>
          <p>
            Fokusera på att beskriva önskat resultat snarare än att detaljstyra
            tekniska lösningar. Låt entreprenörerna föreslå hur resultatet bäst
            kan uppnås.
          </p>
          <h3>4. Utvärdera och kvalitetssäkra anbud</h3>
          <p>
            Begär minst tre anbud för att skapa en konkurrenskraftig
            upphandling. Vid utvärderingen bör ni:
          </p>
          <ul>
            <li>Jämföra anbuden systematiskt mot samma kriterier</li>
            <li>
              Kontrollera att alla offererade arbeten täcker samma omfattning
            </li>
            <li>Granska reservationer och villkor noggrant</li>
            <li>Kontakta referenser från tidigare, liknande projekt</li>
            <li>Ställa kompletterande frågor vid oklarheter</li>
          </ul>
          <p>
            Säkerställ att ni jämför likvärdiga erbjudanden genom att
            "nollställa" anbuden mot förfrågningsunderlaget.
          </p>
          <h3>5. Upprätta ett entreprenadkontrakt</h3>
          <p>
            Använd alltid standardiserade entreprenadkontrakt enligt
            branschpraxis:
          </p>
          <ul>
            <li>
              ABT 06 för totalentreprenader (där entreprenören ansvarar för både
              projektering och utförande)
            </li>
            <li>
              AB 04 för utförandeentreprenader (där beställaren ansvarar för
              projekteringen)
            </li>
          </ul>
          <p>
            Ett korrekt formulerat avtal skyddar föreningen vid tvister och
            säkerställer att båda parter förstår sina åtaganden.
          </p>
          <h3>6. Tydliggör ansvarsfördelningen</h3>
          <p>
            Utse en huvudansvarig projektledare – antingen från styrelsen eller
            en extern konsult. Denna person behöver kompetens inom flera
            områden:
          </p>
          <ul>
            <li>
              <strong>Juridik:</strong> Säkerställa att branschregler och
              myndighetskrav följs, hantera avtalsfrågor
            </li>
            <li>
              <strong>Byggteknik:</strong> Fatta tekniska beslut och bedöma
              entreprenörens arbete
            </li>
            <li>
              <strong>Ekonomi:</strong> Övervaka kostnader, hantera
              fakturaflöden och ÄTA-arbeten
            </li>
            <li>
              <strong>Kommunikation:</strong> Informera medlemmar och hantera
              frågor från berörda parter
            </li>
          </ul>
          <p>
            En tydlig ansvarsfördelning förebygger missförstånd och säkerställer
            att viktiga aspekter inte förbises.
          </p>
          <h3>7. Skapa en kommunikationsplan</h3>
          <p>
            Byggprojekt påverkar alla boende och verksamma i fastigheten. En
            genomtänkt kommunikationsplan bör specificera:
          </p>
          <ul>
            <li>
              Vilka målgrupper som berörs (medlemmar, hyresgäster,
              näringsidkare)
            </li>
            <li>
              Vilka informationskanaler som är lämpliga (möten, anslag, digitala
              plattformar, utskick)
            </li>
            <li>
              Vilken information som behövs före, under och efter projektets
              genomförande
            </li>
            <li>Hur synpunkter och frågor ska hanteras och besvaras</li>
            <li>Vilka personer som är kontaktpersoner för olika frågor</li>
          </ul>
          <p>
            Regelbunden och tydlig kommunikation ökar förståelsen för projektet
            och minskar risken för missnöje.
          </p>
          <h3>8. Genomför slutförhandling och kontraktsignering</h3>
          <p>Innan kontraktet undertecknas bör ni:</p>
          <ul>
            <li>Hålla en slutförhandling där alla detaljer fastställs</li>
            <li>
              Säkerställa att alla parter har samma förståelse av projektets
              omfattning
            </li>
            <li>
              Justera kontraktet vid behov och revidera priser om omfattningen
              ändras
            </li>
            <li>
              Klargöra rutiner för ändringar, tillägg och avgående arbeten (ÄTA)
            </li>
          </ul>
          <p>
            En omsorgsfullt genomförd slutförhandling förebygger framtida
            tvister.
          </p>
          <h3>9. Prioritera projekteringen</h3>
          <p>
            Lägg stor vikt vid projekteringen – den är grunden för ett lyckat
            genomförande. En noggrann projektering:
          </p>
          <ul>
            <li>Minskar risken för oförutsedda kostnader och förseningar</li>
            <li>Ger tydliga underlag för entreprenören att arbeta efter</li>
            <li>Underlättar uppföljning och kvalitetskontroll</li>
            <li>Ger bättre underlag vid eventuella tvister</li>
          </ul>
          <p>
            Investera tid och resurser i projekteringsfasen för att spara
            kostnader under genomförandet.
          </p>
          <h3>10. Genomför besiktning och uppföljning</h3>
          <p>
            När projektet är slutfört ska en oberoende besiktningsman anlitas
            för slutbesiktning.
          </p>
          <p>Besiktningen:</p>
          <ul>
            <li>
              Kontrollerar att arbetet uppfyller avtalade krav och tekniska
              specifikationer
            </li>
            <li>
              Identifierar eventuella brister som entreprenören behöver åtgärda
            </li>
            <li>
              Dokumenterar genomfört arbete som underlag för framtida
              underhållsplanering
            </li>
          </ul>
          <p>
            Efter godkänd slutbesiktning löper en garantitid på normalt fem år,
            följd av ytterligare fem års ansvarstid. Planera för
            garantibesiktning innan garantitiden löper ut.
          </p>
          <h3>Sammanfattning</h3>
          <p>
            Framgångsrika byggprojekt i bostadsrättsföreningar bygger på
            noggrann planering, professionell upphandling och tydlig
            projektledning. Genom att följa dessa tio steg kan ni minimera
            risker, optimera resursutnyttjandet och säkerställa långsiktigt
            hållbara lösningar för föreningen och dess medlemmar.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Blog6;
