import React from "react";
import "../../LandingPage.css";
import LandingHeader from "../../LandingHeader/LandingHeader";
import LandingFooter from "../../LandingFooter/LandingFooter";

const Blog5 = () => {
  return (
    <>
      <LandingHeader />
      <div className="destination-page child-blog-page">
        <h2>Lyckas med din underhållsplan</h2>
        <div>
          <h3>Kort sammanfattning</h3>
          <p>
            En underhållsplan är ett viktigt verktyg för fastighetsägare och
            bostadsrättsföreningar. Den beskriver nödvändigt underhåll för att
            bevara fastighetens värde och ger en översikt över planerade
            åtgärder och kostnader. För att skapa en plan bör man besiktiga
            fastigheten, inventera komponenter, planera tidslinjer och kostnader
            samt använda digitala verktyg. Planen bör uppdateras regelbundet
            genom besiktningar och kostnadsjusteringar. Ett långsiktigt
            perspektiv är avgörande för framgångsrik underhållsplanering.
          </p>
          <h3>Underhållsplanering: Steg för att lyckas</h3> 
          <h3>Vad är en underhållsplan?</h3>
          <p>
            En underhållsplan är ett ovärderligt verktyg för fastighetsägare,
            bostadsrättsföreningar (BRF) och fastighetsförvaltare. Den
            dokumenterar det underhåll som krävs för att bevara fastighetens
            värde över tid och ger en översikt över planerade åtgärder och deras
            kostnader. Genom att systematiskt arbeta med en underhållsplan kan
            fastighetsägare undvika kostsamma överraskningar och sänka
            driftskostnaderna. En typisk underhållsplan sträcker sig vanligtvis
            över 30 till 50 år och erbjuder en tydlig överblick över framtida
            investeringar och underhållsbehov.
          </p>
          <h3>Hur skapar man en underhållsplan?</h3>
          <p>Följande steg rekommenderas för att skapa en underhållsplan:</p>
          <p>
            <strong>1. Besiktning av fastigheten:</strong> Inled med en grundlig
            inspektion av alla delar av fastigheten, inklusive fasad, tak,
            värmesystem och ledningar. Dokumentera nuvarande skick och
            identifiera eventuella skador eller slitage.
          </p>
          <p>
            <strong>2. Inventering av komponenter:</strong> Upprätta en
            detaljerad lista över fastighetens olika delar, exempelvis fönster,
            hissar och ventilationssystem. Ange livslängd och underhållsbehov
            för varje komponent.
          </p>
          <p>
            <strong>3. Tidsplan för åtgärder:</strong> Planera när olika
            underhållsåtgärder ska genomföras under en period på 30–50 år.
          </p>
          <p>
            <strong>4. Kostnadsuppskattning:</strong> Beräkna kostnader för
            varje åtgärd, med hänsyn till inflation och aktuella marknadspriser.
          </p>
          <p>
            <strong>5. Användning av digitala verktyg:</strong> Ett webbaserat
            underhållsverktyg kan underlätta planeringen och göra det enklare
            att uppdatera planen vid behov.
          </p>
          <h3>Hur uppdaterar man en underhållsplan?</h3>
          <p>
            En underhållsplan ska betraktas som ett levande dokument som
            regelbundet uppdateras:
          </p>
          <ul>
            <li>
              <strong>Förnyad besiktning:</strong> Genomför nya inspektioner för
              att identifiera förändrade underhållsbehov.
            </li>
            <li>
              <strong>Uppdatera kostnadsuppskattningar:</strong> Justera
              kostnader baserat på aktuella marknadspriser och erfarenheter från
              tidigare underhållsarbete.
            </li>
            <li>
              <strong>Använd en mall:</strong> Organisera informationen
              effektivt med en strukturerad mall.
            </li>
            <li>
              <strong>Beakta regionala skillnader:</strong> Klimat och
              geografiska faktorer kan påverka underhållsbehoven.
            </li>
          </ul>
          <h3>Hur använder man en underhållsplan?</h3>
          <p>
            En underhållsplan bör vara lättanvänd och integreras i den dagliga
            fastighetsförvaltningen:
          </p>
          <ul>
            <li>
              <strong>Koppla planen till budgeten:</strong> Jämför planerade
              kostnader med budget och framtida avsättningar till
              underhållsfonden.
            </li>
            <li>
              <strong>Uppdatera löpande:</strong> Dokumentera genomförda
              underhållsåtgärder och justera framtida åtgärder.
            </li>
            <li>
              <strong>Kombinera underhållsarbete:</strong> Samordna flera
              åtgärder för att minska kostnader och minimera olägenheter för
              boende.
            </li>
            <li>
              <strong>Planera i god tid:</strong> Boka entreprenörer tidigt för
              att säkerställa rätt kompetens och bästa pris.
            </li>
          </ul>
          <h3>Underhållsplanen som ett levande dokument</h3>
          <p>
            Underhållsplanen ska kontinuerligt uppdateras och anpassas efter
            förändrade förhållanden:
          </p>
          <ul>
            <li>
              <strong>Revidering årligen:</strong> Inför budgetarbetet bör
              planen ses över för att justera kostnader och notera utförda
              åtgärder.
            </li>
            <li>
              <strong>Okulär besiktning:</strong> Genomför en mer omfattande
              besiktning vart 4–5 år för att uppdatera planen.
            </li>
            <li>
              <strong>Bevara historik:</strong> Dokumentera tidigare underhåll
              för att undvika att viktig information går förlorad vid
              styrelsebyten.
            </li>
          </ul>
          <h3>Långsiktighet – nyckeln till framgångsrik underhållsplanering</h3>
          <p>
            För att lyckas med underhållsplaneringen krävs ett långsiktigt
            perspektiv. Det handlar om att förhindra att akuta åtgärder blir
            nödvändiga och att planera för framtida investeringar:
          </p>
          <ul>
            <li>
              <strong>Fastställ ansvar:</strong> Klargör vem som ansvarar för
              att hålla planen aktuell.
            </li>
            <li>
              <strong>Revidera årligen och vart femte år:</strong> Kombinera små
              årliga revideringar med mer omfattande granskningar vart femte år.
            </li>
            <li>
              <strong>Håll koll på komponenters livslängd:</strong> Faktorer som
              materialkvalitet och geografiska förhållanden påverkar
              underhållsbehovet.
            </li>
            <li>
              <strong>Samordna åtgärder:</strong> Genom att samla flera åtgärder
              vid samma tillfälle kan arbetskostnader sänkas.
            </li>
          </ul>
          <h3>Avslutande tankar</h3>
          <p>
            Underhållsplanering är en grundläggande del av framgångsrik
            fastighetsförvaltning. Genom att ha en levande och långsiktig plan
            för fastighetens underhåll kan fastighetsägare förhindra onödiga
            kostnader och samtidigt säkra fastighetens värde och funktion över
            tid.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Blog5;
