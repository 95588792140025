import React from "react";
import "../../LandingPage.css";
import LandingHeader from "../../LandingHeader/LandingHeader";
import LandingFooter from "../../LandingFooter/LandingFooter";

const Blog1 = () => {
  return (
    <>
      <LandingHeader />
      <div className="destination-page child-blog-page">
        <h2>Att tänka på när man börjar med underhållsplanering</h2>
        <div>
          <h3>Smarta val för en hållbar underhållsplan</h3>
          <p>
            Att börja med underhållsplanering är ett viktigt steg mot ett mer
            proaktivt och kostnadseffektivt fastighetsunderhåll. Många konsulter
            och systemleverantörer föreslår att man tar hjälp av experter för
            att skapa en plan baserad på fastighetens faktiska status – något vi
            också varmt rekommenderar. Samtidigt vet vi att detta inte alltid
            blir verklighet.
          </p>
           <h3>Varför saknas underhållsplaner?</h3>
          <p>
            Många fastighetsägare har ingen plan alls eller använder en
            föråldrad version som inte uppdaterats på flera år. Ofta beror det
            på att det saknas tid, resurser eller kunskap att hålla planen
            aktuell. Ibland agerar man bara när problem uppstår eller
            hyresgästernas behov förändras. Dessutom ser vissa inte poängen med
            att investera i en omfattande plan tillsammans med en konsult.
          </p>
          <p>
            Men faktum är att en genomtänkt underhållsplan sparar pengar,
            förebygger problem och skapar trygghet – för både fastighetsägare
            och boende.
          </p>
           <h3>Enkla steg för att komma igång</h3>
          <p>
            Det behöver inte vara komplicerat att ta första steget. Börja vid
            skrivbordet genom att samla in grundläggande information om
            fastigheterna. Även utan en detaljerad statusbesiktning får ni en
            bättre överblick över framtida behov. En enkel plan är alltid bättre
            än ingen plan alls – oavsett om ni är en bostadsrättsförening eller
            en kommun.
          </p>
          <p>
            Kom ihåg att ju mer detaljerad planen är, desto mer tid krävs för
            att hålla den uppdaterad. Hitta rätt ambitionsnivå för er situation.
          </p>
           <h3>Kostnadskontroll och fackmannainsatser</h3>
          <p>
            Kostnader för underhåll är alltid uppskattningar – det slutgiltiga
            priset vet ni först när arbetet är klart. För åtgärder i närtid kan
            det löna sig att anlita en fackman för att bedöma omfattningen och
            om arbetet kan samordnas med andra projekt.
          </p>
          <p>
            Att skjuta på åtgärder eller göra små reparationer successivt blir
            ofta dyrare i längden. Därför rekommenderar vi att ni tar hjälp för
            att göra kloka beslut och hantera skador innan de eskalerar.
          </p>
          <h3>Excel eller specialiserade system?</h3>
          <p>
            Många börjar med att skapa sin underhållsplan i Excel – och det är
            ett helt okej alternativ. Excel är kostnadseffektivt och
            lättillgängligt. Men när planen blir mer omfattande kan det bli
            tidskrävande att hålla ordning på struktur och uppdateringar.
            Dessutom är Excel begränsat när det gäller att dela och
            kvalitetssäkra data.
          </p>
          <p>
            Därför föreslår vi att ni tittar på webbaserade system som är
            utvecklade för att förenkla både planering och uppdateringar. Med
            rätt systemstöd får ni en hållbar plan som är enkel att förvalta och
            utveckla.
          </p>
          <h3>Nästa steg</h3>
          <p>
            Oavsett om ni väljer att börja enkelt eller med professionell hjälp
            är det viktigaste att ni börjar. Fundera över vilken
            detaljeringsnivå ni behöver och vilket stöd som kan göra arbetet
            enklare. Pröva gärna med att skapa ett konto i vår tjänst och använd
            guiden för att skapa din underhållsplan.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Blog1;
