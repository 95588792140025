import React from "react";
import "../../LandingPage.css";
import LandingHeader from "../../LandingHeader/LandingHeader";
import LandingFooter from "../../LandingFooter/LandingFooter";

const Blog2 = () => {
  return (
    <>
      <LandingHeader />
      <div className="destination-page child-blog-page">
        <h2>Effektiv underhållsplan för din fastighet</h2>
        <div>
          <h3>Kort summering </h3>
          <p>
            En underhållsplan är ett avgörande verktyg för långsiktig
            fastighetsförvaltning. Den hjälper fastighetsägare och
            bostadsrättsföreningar att hålla sina byggnader i gott skick och
            planera för framtida underhåll. Processen börjar med en förstudie,
            där fastighetens förutsättningar som byggår och konstruktion
            kartläggs. Därefter genomförs en noggrann besiktning för att
            identifiera behov av åtgärder, följt av en sammanställning av
            underhållsåtgärder, kostnader och tidsplan. Underhållsplanen bör
            uppdateras årligen för att hålla den aktuell. Fördelarna med en bra
            underhållsplan inkluderar lägre driftskostnader, längre livslängd på
            byggnader, bättre ekonomisk balans och högre kreditvärdighet. Genom
            att aktivt använda och uppdatera planen kan föreningar förebygga
            oväntade kostnader och säkerställa en hållbar fastighetsförvaltning.
          </p>
           <h3>Så skapar du en effektiv underhållsplan för din fastighet</h3>
          <p>
            En väl utformad underhållsplan är ett av de mest värdefulla
            verktygen för att säkerställa långsiktig hållbarhet och god ekonomi
            i fastighetsförvaltning, särskilt för bostadsrättsföreningar. En
            underhållsplan gör det möjligt att förutse och planera för framtida
            underhåll, vilket kan bidra till lägre kostnader, bättre
            fastighetsvärde och effektivare förvaltning. Men att skapa en
            underhållsplan är inte en enkel uppgift – det kräver noggrannhet,
            expertis och tid. Här går vi igenom hur man systematiskt kan arbeta
            fram en underhållsplan och vad den bör innehålla.
          </p>
           <h3>Steg 1: Förstudie och insamling av information</h3>
          <p>
            Det första steget i att skapa en underhållsplan är att noggrant
            inventera fastigheten eller fastigheterna som ska ingå i planen.
            Denna förstudie syftar till att kartlägga viktiga faktorer som
            fastighetens:
          </p>
          <ul>
            <li>Byggår</li>
            <li>Utformning och konstruktion</li>
            <li>Fastighetstyp (bostadshus, industrilokal etc.)</li>
          </ul>
          <p>
            Det är också viktigt att samla in relevanta dokument och underlag
            som kan hjälpa i planeringen. Exempel på sådant underlag är
            ritningar, besiktningsprotokoll, inventarielistor och information om
            tidigare utförda arbeten. Att digitalisera och systematisera dessa
            dokument underlättar för framtida uppdateringar och användning av
            underhållsplanen.
          </p>
           <h3>Steg 2: Fastighetsbesiktning</h3>
          <p>
            Efter att förstudien är klar genomförs en noggrann besiktning av
            fastigheten. Detta steg är avgörande för att fastställa den
            nuvarande statusen på byggnadens olika delar och identifiera
            eventuella behov av underhåll. Under besiktningen granskas:
          </p>
          <ul>
            <li>Byggnadsdelar som tak, fasader och fönster</li>
            <li>Inre utrymmen som källare, vindar och garage</li>
            <li>Installationer för exempelvis värme, vatten och avlopp</li>
          </ul>
          <p>
            Denna besiktning görs ofta i samarbete med representanter från
            styrelsen och bör inkludera en detaljerad genomgång av byggnadens
            skick och behov av åtgärder. Det är också viktigt att dokumentera
            besiktningen genom mätningar, fotografering och anteckningar.
          </p>
           <h3>Steg 3: Sammanställning av underhållsplan</h3>
          <p>
            När all information är samlad och besiktningen genomförd är det dags
            att sammanställa underhållsplanen. Här ska alla insamlade data
            bearbetas och analyseras för att skapa en tydlig och strukturerad
            plan. Underhållsplanen bör innehålla:
          </p>
          <ul>
            <li>
              <strong>Vad som ska underhållas:</strong> En lista över alla
              byggnadsdelar och installationer som behöver åtgärdas.
            </li>
            <li>
              <strong>När det ska underhållas:</strong> Tidsplan för när
              åtgärder bör genomföras och hur ofta de behöver upprepas.
            </li>
            <li>
              <strong>Kostnadsberäkningar:</strong> Estimerade kostnader för
              alla planerade åtgärder, samt en uppskattning av hur mycket pengar
              som bör avsättas till underhållsfond.
            </li>
          </ul>
          <p>
            Det är också viktigt att göra planen lättanvänd och förståelig, så
            att även personer utan teknisk bakgrund kan använda den i praktiken.
          </p>
           <h3>Steg 4: Uppdatering och användning av underhållsplanen</h3>
          <p>
            En underhållsplan är ett levande dokument som behöver uppdateras
            regelbundet. Det är rekommenderat att genomföra en årlig uppföljning
            där förvaltaren, gärna tillsammans med styrelsen, går igenom och
            uppdaterar planen baserat på förändringar i fastighetens status och
            ekonomiska situation.
          </p>
          <p>
            Vid varje uppdatering bör man stämma av om några åtgärder behöver
            flyttas fram eller genomföras tidigare än planerat. Genom att hålla
            planen aktuell kan föreningen undvika oväntade utgifter och skapa en
            långsiktig hållbar budget.
          </p>
          <h3>Steg 5: Genomförande och projektering</h3>
          <p>
            När underhållsplanen är upprättad och godkänd är det dags att
            genomföra de åtgärder som är planerade. För att detta ska gå så
            smidigt som möjligt är det viktigt att planera noggrant för
            upphandling av entreprenörer, val av material och samordning av
            olika arbetsinsatser. Riksbyggen och andra förvaltningstjänster kan
            hjälpa till att hantera projektering, upphandling och genomförande
            av åtgärder.
          </p>
           <h3>Fördelar med en underhållsplan</h3>
          <p>
            En väl genomförd underhållsplan ger många fördelar för både
            fastighetsägare och boende. Några av de främsta fördelarna är:
          </p>
          <ul>
            <li>
              <strong>Lägre driftskostnader:</strong> Genom att planera
              underhållet i förväg kan föreningar förhindra oväntade och dyra
              reparationer.
            </li>
            <li>
              <strong>Jämnare slitage: </strong>Genom att underhålla byggnaden i
              rätt tid kan man förhindra snabbare slitage och förlänga
              byggnadens livslängd.
            </li>
            <li>
              <strong>Ekonomisk balans:</strong> En underhållsplan gör det
              möjligt att budgetera och avsätta medel långsiktigt, vilket leder
              till en mer stabil ekonomi.
            </li>
            <li>
              <strong>Förbättrad kreditvärdighet:</strong> En väldokumenterad
              och uppdaterad underhållsplan kan öka föreningens kreditvärdighet,
              vilket kan underlätta framtida finansiering.
            </li>
          </ul>
          <p>
            En bra underhållsplan fungerar som en "servicebok" för fastigheten
            och gör det möjligt för bostadsrättsföreningar att ta hand om sina
            fastigheter på ett mer effektivt och kostnadseffektivt sätt.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Blog2;
