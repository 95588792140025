import React from "react";
import "../LandingPage.css";
const Terms = () => {
  return (
    <>
      <h2
        className="terms-title"
        style={{
          background: "#333f50",
          padding: "1rem 0",
          color: "white",
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "24px",
        }}
      >
        Allmänna villkor Janus – abonnemang (SaaS)
      </h2>
      <div className="destination-page">
        <div>
          <h3 className="">1. Inledning</h3>
          <p className="">
            Dessa Allmänna villkor (AV) reglerar din användning av Janus och
            helt eller delvis integrerade Tilläggstjänster, tillsammans
            fortsättningsvis benämnda som Tjänsterna. Tjänsterna tillhandahålls
            av BalancePoint AB. Du köper tillgång till Tjänsterna direkt från
            BalancePoint AB. Dessa AV gäller mellan BalancePoint AB och dig och
            reglerar BalancePoint AB:s skyldigheter gentemot dig som Kund och
            dina skyldigheter som Kund och Användare av Tjänsterna. I AV
            förekommer begrepp med inledande versal. I de fall begreppen inte
            definierats direkt i texten återfinns definitionerna i punkt 20
            nedan.
            <br />
            <br />
            Kunden får tillgång till Tjänsterna då dessa AV har accepterats,
            vilket sker i samband med signering av avtalet.
            <br />
            <br />
            AV gäller oavsett om programvaran har tillhandahållits kostnadsfritt
            eller mot betalning.
          </p>
        </div>
        <div>
          <h3 className="">2. Meddelanden</h3>
          <p className="">
            Meddelanden om Tjänsterna lämnas via Tjänsternas gränssnitt.
            Meddelande kan också lämnas på relevant hemsida.
            <br />
            <br />
            Meddelandet är att anse som levererat när det har publicerats.
            Exempel på meddelanden är information om driftsstörningar, nya
            versioner, övrig information om programvaran, support eller
            villkorsändringar. BalancePoint AB kan på eget val även sända
            meddelande via e-post eller post. I sådant fall är meddelandet att
            anse som levererat när det avsänts från BalancePoint AB.
            <br />
            <br />
            Meddelande äger tillämpning omedelbart, om inget annat särskilt
            anges i meddelandet.
            <br />
            <br />
            Meddelande från Kunden till BalancePoint AB avseende AV skickas i
            första hand via e-post till adress angiven på BalancePoint AB
            hemsida.
          </p>
        </div>
        <div>
          <h3 className="">3. Abonnemangsavtal</h3>
          <p className="">
            Tjänsterna tillhandahålls som “Software as a Service” (SaaS), där
            Kunden köper ett abonnemang på Tjänsterna som görs tillgängliga
            online. Vid köp av abonnemang ges Kunden rätt till åtkomst av
            Tjänsterna och rätt att använda dem på det sätt som framgår av AV.
            Alla delar av Tjänsterna regleras av AV, inklusive delar som läggs
            till, köps till eller tas i bruk vid ett senare tillfälle.
            <br />
            <br />
            Kunden erhåller en begränsad, uppsägningsbar, icke-exklusiv och icke
            överförbar licens att använda Tjänsterna i enlighet med AV för
            Kundens interna affärsverksamhet mot betalning av avgifter enligt
            vid var tidpunkt gällande prislista eller enligt avtal. Betalning av
            avgifter enligt avtal och uppfyllande av AV är en nödvändig
            förutsättning för rätten att använda Tjänsterna.
            <br />
            <br />
            Tjänsterna tillhandahålls i befintligt skick. Rätten att använda
            Tjänsterna är inte villkorade av eller beroende av någon särskild
            version av Tjänsterna eller funktion vid någon särskild tidpunkt,
            utan ger tillgång till och rätt att använda Tjänsterna såsom de
            tillhandahålls vid varje given tidpunkt. Tillhandahållandet av
            Tjänsterna är inte villkorade av leverans av framtida versioner
            eller funktionaliteter, och inte heller beroende av publikationer,
            material eller kommentarer rörande desamma som görs av eller för
            BalancePoint AB.
            <br />
            <br />
            BalancePoint AB förbehåller sig rätten att tillhandahålla tjänsterna
            från ett annat land.
            <br />
            <br />
            BalancePoint AB förbehåller sig rätten att efter eget gottfinnande
            göra förbättringar, tillägg och ändringar, eller att ta bort
            funktionaliteter, eller korrigera fel eller brister i Tjänsterna.
            BalancePoint AB friskriver sig från ansvar som härrör från sådana
            åtgärder. Om en sådan ändring, mot förmodan, sätter funktioner ur
            spel eller tar bort sådan funktion som utgör en väsentlig del av
            Tjänsterna permanent, äger Kunden rätt att avsluta abonnemanget
            omedelbart. Kunden äger därvid rätt till proportionell återbetalning
            av i förväg erlagda avgifter avseende den eller de delar av
            Tjänsterna som påverkats.
            <br />
            <br />
            BalancePoint AB förbehåller sig rätten att med 60 dagars varsel
            ändra villkoren i AV samt övriga villkor för leverans av Tjänsterna.
            Om Kund inte accepterar sådan ändring, kan abonnemanget av
            Tjänsterna sägas upp i enlighet med punkten 9 i dessa AV. Kunden
            äger rätt till proportionell återbetalning av i förväg erlagda
            avgifter som således inte kunnat nyttjas av Kunden.
            <br />
            <br />
            BalancePoint AB har rätt att anlita underleverantör för fullgörande
            av BalancePoint AB åtagande enligt Avtalet. BalancePoint AB ansvarar
            för utförandet av de avtalsförpliktelser som utförs av
            underleverantörer såsom de hade utförts av BalancePoint AB själv.
            <br />
            <br />
            Vid återkallande av order efter påskrivet avtal förbehåller
            BalancePoint AB sig rätten att fakturera faktiska kostnader samt 25%
            av startavgiften och abonnemangsavgiften de första 12 månaderna.
            <br />
            <br />
            Kunden har inte under några omständigheter rätt att överföra eller
            överlåta, helt eller delvis, någon licens för Tjänsterna till tredje
            part, (innefattande men inte uteslutande vid fusioner och fissioner,
            konkurs, ändring av ägande eller kontroll eller till närliggande
            bolag) om inte skriftligt godkännande erhålls från BalancePoint AB
            dessförinnan.
          </p>
        </div>

        <div>
          <h3 className="">4. Nyttjanderätt</h3>
          <p className="">
            När Kunden köpt ett abonnemang av Tjänsterna och accepterat AV får
            Kunden rätt att använda Tjänsterna under abonnemangstiden för så
            många användare som Kunden köpt abonnemang för. Kunden kan efter
            eget val köpa till rätt för fler Användare att använda Tjänsterna
            eller rätt att använda helt eller delvis integrerade
            Tilläggstjänster enligt vid var tid gällande prislista eller avtal.
            <br />
            <br />
            Endast Användare med ett betalt och giltigt abonnemang har rätt att
            använda Tjänsterna. Användarlicenser utfärdas för enskilda namngivna
            Användare. Användarkonton skapas och administreras av Kunden.
            Användarlicenser får inte delas eller användas av fler än en
            Användare men Kunden kan fritt överföra en användarlicens från en
            Användare till en annan. BalancePoint AB förbehåller sig rätten att
            kontrollera antalet utnyttjade användarlicenser.
            <br />
            <br />
            Varje Användare är ansvarig för sekretessen och riktigheten avseende
            inloggningsuppgifter och annan kontoinformation. Kunden och/eller
            Användaren måste informera BalancePoint AB omedelbart vid obehörig
            åtkomst av inloggningsuppgifter.
            <br />
            <br />
            Kunden är medveten om att användande av tjänsten förutsätter
            tillgång till sådan programvara, utrustning och kommunikationstjänst
            som krävs för att utnyttja tjänsterna. Dessa framgår av BalancePoint
            AB hemsida eller meddelas av BalancePoint AB vid förfrågan.
            <br />
            <br />
            Användare ska inte överföra virus, maskar eller skadlig kod av något
            slag till Tjänsterna eller genom att använda Tjänsterna. Tjänsterna
            får inte användas för något illegalt eller obehörigt syfte.
            <br />
            <br />
            Användare ska inte bryta mot någon lagstiftning i relevant
            jurisdiktion/relevanta jurisdiktioner, innefattande men inte
            uteslutande upphovsrättslagstiftning eller överföring av stötande,
            hotande, kränkande, ärekränkande eller annan anstötlig data till
            Tjänsterna.
          </p>
        </div>

        <div>
          <h3 className="">5. Uppstart av tjänsterna</h3>
          <p className="">
            BalancePoint AB ska tillhandahålla Kunden tjänsterna från och med
            Startdagen, vilket sker genom att BalancePoint AB tillhandahåller
            Kunden inloggningsuppgifter och/eller eventuella andra anvisningar.
            Startdagen inträder när BalancePoint AB gjort erforderliga
            inloggningsuppgifter och andra anvisningar för åtkomst av Tjänsterna
            tillgängliga för Kunden utan krav på något särskilt godkännande från
            Kunden. Tilläggstjänster kan göras tillgängliga vid separat
            tidpunkt. Detta påverkar då inte Startdagen.
          </p>
        </div>
        <div>
          <h3 className="">6. Begränsningar i Tjänsterna</h3>
          <p className="">
            Maximal lagring för Kunden i tjänsternas grundutförande är anges i
            abonnemanget.
          </p>
        </div>
        <div>
          <p className="Inledning_heading integritet_heading">
            7. Databehandling och integritet
          </p>
          <div>
            <p className="Databehandling_heading">
              7.1 Behandling av personuppgifter
            </p>

            <p className="">
              Kundens användande av Tjänsterna kan komma att innebära att
              personuppgifter överförs från Kunden till BalancePoint AB. Kunden
              är därvid (enligt EU:s Dataskyddsförordning ”GDPR”) att betrakta
              som personuppgiftsansvarig och BalancePoint AB som
              personuppgiftsbiträde. Enligt GDPR ska ett särskilt avtal
              ”Databehandlingsavtal” avseende sådan överföring och behandling
              upprättas mellan parterna med bl.a. de instruktioner som ska gälla
              för behandlingen. Det Databehandlingsavtal som ska gälla avseende
              Kundens användande av Tjänsterna tillsammans med dessa AV är
              bifogat som bilaga till dessa AV.
            </p>
          </div>
          <div>
            <p className="Databehandling_heading">7.2 Lagring</p>

            <p className="">
              BalancePoint AB lagrar användarinformation i form av e-postadress,
              namn och telefonnummer för användare samt adressuppgifter för
              företaget. När avtalet upphör raderas kontot automatiskt efter 30
              dagar.
            </p>
          </div>
          <div>
            <p className="Databehandling_heading">
              7.3 Insamling av information
            </p>

            <p className="">
              BalancePoint AB kan komma att samla in information från
              användningen av tjänsterna via automatiserade
              datainsamlingsverktyg. BalancePoint AB samlar in och använder
              sådan information i syfte att säkerställa, underhålla och
              förbättra produkter och tjänster samt för statistik och analyser
              av olika slag.
            </p>
          </div>
          <div>
            <p className="Databehandling_heading">
              7.4 Insamling och presentation av identifikationsdata
            </p>

            <p className="">
              Kunden samtycker härmed uttryckligen att BalancePoint AB får samla
              in samt visa och överföra Kundens identifikationsdata och
              profilinformation till BalancePoint AB:s databas och delge
              informationen till andra. Om Kunden inte önskar vara registrerad i
              BalancePoint AB:s databas ska Kunden kontakta BalancePoint AB.
            </p>
          </div>
          <div>
            <p className="Databehandling_heading">7.5 Informationssäkerhet</p>

            <p className="">
              Om inte annat anges i dessa AV, kommer BalancePoint AB inte att
              sälja, hyra, hyra ut eller på annat sätt göra insamlad Data
              tillgänglig för tredje part, utom i följande situationer; (i) för
              att följa lag, förordning eller föreskrift, eller för att svara på
              en lagakraftvunnen begäran från myndigheter eller polis, såsom ett
              domstolsbeslut, beslut eller föreläggande; (ii) för att undersöka
              eller förhindra säkerhetshot eller bedrägeri; (iii) för det fall
              omorganisation, fusion, försäljning eller köp av hela eller delar
              av BalancePoint AB, då personlig information kan komma att
              avslöjas som del av omorganisationen eller sammanslagningen eller
              till faktiska och framtida köpare. BalancePoint AB kommer i alla
              sådana fall att säkerställa att sådana parter iakttar de villkor
              som följer häri, och meddela att information lämnats ut.
            </p>
          </div>
        </div>

        <div>
          <h3 className="">8. Prissättning och fakturering</h3>
          <p className="">
            Om inget annat anges i Avtalet, följer avgifter och
            faktureringsperioder för Tjänsterna de priser som vid var tidpunkt
            görs tillgängliga av BalancePoint AB.
            <br />
            <br />
            Abonnemangsavgifter debiteras normalt i förskott, om inget annat
            anges. De delar av Tjänsterna som i förekommande fall har en avgift
            per transaktion debiteras normalt i efterskott. BalancePoint AB
            förbehåller sig rätten att ändra priset för Tjänsterna för kommande
            perioder.
            <br />
            <br />
            Betalningsvillkor är normalt 30 dagar. Avgifter, till exempel
            fakturaavgift, debiteras enligt de villkor som vid var tid tillämpas
            av BalancePoint AB. Moms tillkommer på angivna priser.
            <br />
            <br />
            Dröjsmålsränta är av Riksbanken fastställd referensränta plus 8
            procentenheter.
          </p>
        </div>
        <div>
          <h3 className="">9. Avtalsperiod och uppsägning</h3>
          <div>
            <p className="Databehandling_heading">9.1 Övriga abonnemang</p>

            <p className="">
              Abonnemanget gäller från och med Startdagen och fram tills
              årsskiftet. Om inget annat anges i Avtalet gäller Avtalet i tolv
              (12) månader, efter vilken perioden automatiskt förlängs med tolv
              (12) månader i taget såvida endera part inte skriftligen säger upp
              Avtalet senast sextio (60) dagar före respektive avtalsperiods
              utgång. Har annan förlängningstid angivits i avtalet gäller denna.
              <br />
              <br />
              Uppsägning av detta Avtal, antingen i sin helhet eller av vissa
              delar eller visst antal Användare, ska ske skriftligen och gäller
              från den dag motparten mottog uppsägningen. Eventuella i förskott
              erlagda avgifter återbetalas inte. Som kund ansvarar du för att
              spara den elektroniska kvittensen på uppsagt avtal som alltid
              sänds till kund via e-post.
              <br />
              <br />
              Vid uppsägning av abonnemanget kommer Kundens åtkomst till
              Tjänsterna att spärras efter sista aktiva abonnemangsdagen.
              <br />
              <br />
              Kunden kan minska antalet användare först efter att den inledande
              avtalsperioden löpt ut. Vid önskemål om att abonnemanget ska
              omfatta färre antal användare än de som sammanlagt abonneras av
              kunden måste sådan minskning anmälas skriftligen senast 60 dagar
              före nästa avtalsperiod. Antalet möjliga inloggade användare
              kommer då att anpassas till det nya önskade antalet användare.
            </p>
          </div>
          <div>
            <p className="Databehandling_heading">
              9.2 Standard och Standard Plus{" "}
            </p>

            <p className="">
              För abonnemangen Standard och Standard Plus med månadsvis
              betalning gäller att avtalsperioden är löpande till dess att
              avtalet sägs upp. Uppsägningstid gäller på 1 månad gäller från och
              med kommande månadsskifte. Abonnemanget avslutas i tjänsten.
              <br />
              <br />
              För abonnemangen Standard och Standard Plus med månadsvis
              betalning gäller att avtalsperioden är 1 år till dess att avtalet
              sägs upp. Uppsägningstid gäller på 3 månader gäller från och med
              kommande månadsskifte. Abonnemanget avslutas i tjänsten.
              <br />
              <br />
              Kunden kan när som helst utöka antalet användare. Avtalsperioden
              enligt ovan omfattar även de nya användarna. Ersättning utgår från
              Startdagen.
              <br />
              <br />
              Kunden kan byta mellan abonnemangen under pågående period, om man
              byter från Standard till Standard Plus eller om man byter får
              löpande till 1 års avtalstid, bytet sker då omgående. Om man byter
              från Standard Plus till Standard eller om man byter från 1 års
              avtalstid till löpande så sker förändringen när pågående avtal
              löpt ut.
            </p>
          </div>
        </div>
        <div>
          <h3 className="">10. Förtida upphörande</h3>
          <p className="">
            BalancePoint AB kan säga upp detta Avtal med omedelbar verkan om
            Kunden är i dröjsmål med betalning av avgifter, är på obestånd, är
            försatt i konkurs eller av annan anledning inte kan fullfölja sina
            betalningsförpliktelser eller om Kunden bryter mot detta avtal.
            BalancePoint AB har då rätt att stänga av Tjänsterna helt och hållet
            med omedelbar verkan.
          </p>
        </div>
        <div>
          <h3 className="">11. Tillgänglighet</h3>
          <p className="">
            BalancePoint AB ska tillhandahålla en för kunden säker leverans av
            Tjänsterna. Tjänsterna är normalt tillgängliga via Internet dygnet
            runt sju dagar i veckan. BalancePoint AB (och av dem anlitade
            leverantörer) har rätt att vidta åtgärder som påverkar ovan nämnda
            tillgänglighet, om BalancePoint AB anser det nödvändigt av
            tekniska-, service-, drifts- eller säkerhetsrelaterade skäl.
            Planerade driftsavbrott på grund av systemunderhåll meddelas Kunden
            i förväg. Se BalancePoint AB hemsida för planerade drift- och
            underhållsstopp.
            <br /> <br />
            Oplanerade driftsstopp kan förekomma. I den mån BalancePoint AB är
            ansvarigt för, och kan påverka, sådant driftsstopp, ska BalancePoint
            AB skyndsamt åtgärda felet.
          </p>
        </div>
        <div>
          <h3 className="">12. Säkerhet</h3>
          <p className="">
            BalancePoint AB avser att tillhandahålla säkra och pålitliga
            tjänster och strävar efter att vid varje tidpunkt tillhandahålla
            adekvata administrativa, fysiska och tekniska säkerhetsåtgärder.
            BalancePoint AB genomför regelbundet säkerhetskopiering för att
            säkerställa att Kundens data är i tryggt förvar.
          </p>
        </div>
        <div>
          <h3 className="">13. Support</h3>
          <p className="">
            Avtalet avser inte och innehåller inte kostnadsfri support i någon
            form. Stöd för användaren ges genom den systemdokumentation som
            finns i form av instruktioner, video och skrift.
            <br />
            <br />
            Om kunden önskar hjälp med handhavande av produkten sker detta genom
            löpande konsulttaxa.
            <br />
            <br />
            Avseende fel i programvara eller driftmiljö ska dessa rapporteras
            via e-post.
          </p>
        </div>
        <div>
          <h3 className="">14. Immateriella rättigheter</h3>
          <p className="">
            BalancePoint AB – eller dess licensgivare – är ensam innehavare av
            alla immateriella rättigheter (IPR) hänförliga till Tjänsterna. IPR
            innefattar men är inte begränsad till upphovsrätt, patent,
            varumärken, firma, mönster och produktdesign, källkod, databaser,
            affärsplaner och know-how, oavsett om det är registrerat eller inte.
            All dokumentation, inklusive manualer, användarguider, eller andra
            skrivna, elektroniska eller icke- elektroniska, beskrivningar av hur
            Tjänsterna är upprättade och används (Dokumentation) anses vara del
            av Tjänsterna och är föremål för samma restriktioner. All
            upphovsrätt, varumärken, registrerade varumärken, produktnamn,
            firmor eller logotyper som anges i Tjänsterna eller i anslutning
            till Tjänsterna är respektive ägares egendom.
            <br />
            <br />
            BalancePoint AB gör inga anspråk på immateriella rättigheter, eller
            äganderätt av något slag, till Data ägd av Kunden som överförs till
            Tjänsterna.
            <br />
            <br />
            Om BalancePoint AB tillhandahåller produkter under licens från annan
            leverantör än BalancePoint AB gäller den andre leverantörens
            licensvillkor framför dessa AV.
            <br />
            <br />
            Tjänsten kan vara integrerad med applikationer, webbplatser och
            tjänster från tredje part (”Tredjepartsapplikationer”), i syfte att
            göra innehåll, produkter och/eller tjänster tillgängliga för
            användaren. Dessa Tredjepartsapplikationer kan ha egna
            användningsvillkor och integritetspolicyer och användningen av dessa
            Tredjepartsapplikationer styrs av och är föremål för sådana villkor
            och integritetspolicyer. BalancePoint AB står inte bakom och
            ansvarar inte för beteenden, funktioner eller innehåll i någon
            Tredjepartsapplikation eller för någon transaktion som användaren
            eventuellt ingår med tillhandahållaren av sådan
            Tredjepartsapplikation.
            <br />
            <br />
            Om Kunden gör intrång i BalancePoint AB eller tredje mans IPR, eller
            använder Tjänsterna på ett sätt som strider med AV, ska Kunden
            erlägga ett belopp som motsvarar det högsta av motsvarigheten till
            Abonnemangsavgifterna för fem år avseende de innevarande licenserna
            eller den verkliga skadan. Kunden bekräftar att BalancePoint AB kan
            drabbas av oersättlig skada vid intrång eller skada på IPR, och att
            BalancePoint AB eller dess licensgivare ska ha rätt att använda sig
            av alla rimliga åtgärder för att skydda sina kommersiella intressen,
            och sin egendom, inklusive alla åtgärder som är möjliga enligt lag.
            Motsvarande ska gälla om Kunden har, eller har försökt att erhålla
            information eller data som Kunden inte har rätt till enligt AV.
          </p>
        </div>
        <div>
          <h3 className="">15. Sekretess</h3>
          <p className="">
            Vardera part förbinder sig att inte till tredje man utan motpartens
            skriftliga medgivande utlämna sådana uppgifter om motpartens
            verksamhet som kan vara att betrakta som affärs- eller
            yrkeshemligheter eller som enligt lag omfattas av sekretesskyldighet
            (”Konfidentiell Information”).
            <br />
            <br />
            Part ansvarar för sina respektive anställdas och konsulters
            iakttagande av häri angivna bestämmelser och ska genom
            sekretessförbindelse med dessa eller andra lämpliga åtgärder tillse
            att Avtalets sekretess iakttas.
            <br />
            <br />
            Parts sekretesskyldighet enligt Avtalet gäller under avtalstiden
            samt även för en period om fem (5) år efter det att avtalet har
            upphört att gälla.
          </p>
        </div>
        <div>
          <h3 className="">16. Garanti och garantibegränsning</h3>
          <p className="">
            BalancePoint AB garanterar att Tjänsterna kommer att fungera
            huvudsakligen som beskrivits. Kunden och BalancePoint AB är överens
            om att Tjänsterna och leveransen därav inte är helt fria från fel
            och att förbättringar av Tjänsterna är en pågående process. Kunden
            medger att Tjänsterna är levererad i befintligt skick och används på
            Kundens egen risk.
            <br />
            <br />
            BalancePoint AB garanterar inte att Tjänsterna uppfyller Kundens
            krav, att de fungerar korrekt med Kundens val av utrustning, system
            eller inställningar, och inte heller att den inte avbryts eller är
            fri från fel. Kunden ansvarar för att tredjepartsprogram såsom
            webbläsare, pdf-läsare, toolbars, antivirusprogram samt brandväggar
            är korrekt installerade och tillåter trafik mot av BalancePoint AB
            hänvisade hemsidor. Vidare skall noteras att användning av Internet
            för att använda Tjänsterna varken installeras, underhålls eller
            etableras av BalancePoint AB, och att BalancePoint AB inte har
            kontroll över Internet. BalancePoint AB är inte ansvarig för avbrott
            eller störningar i driften av någon del av BalancePoint AB, och har
            inte heller ansvar för en eventuell reglering av Internet.
            BalancePoint AB skall vidta alla rimliga åtgärder som bedöms
            lämpliga för att åtgärda och undvika sådana händelser, dock
            garanterar BalancePoint AB inte att avbrott inte äger rum.
            BalancePoint AB är inte ansvarig för prestanda i Internettjänster
            eller hur Internetleverantörer utför sina tjänster.
            <br />
            <br />
            Om Tjänsterna inte fungerar i enlighet med ovan angivna begränsade
            garanti, skall BalancePoint AB korrigera alla konstaterade fel eller
            brister i Tjänsterna på egen bekostnad. BalancePoint AB åtgärdar
            anmälda fel i Tjänsterna som på ett allvarligt sätt påverkar
            funktionen i Tjänsterna, så snart det är möjligt. BalancePoint AB
            förbehåller sig dock rätten att avgöra när och hur ett fel ska
            rättas samt när och hur en åtgärd ska utföras. BalancePoint AB
            åtgärdar fel som inte på ett allvarligt sätt påverkar Kundens
            användande av Tjänsterna och/eller Tjänsternas funktion, tidigast
            vid nästa officiella version av Tjänsterna.
            <br />
            <br />
            Tjänsterna levereras i befintligt skick och varken BalancePoint AB
            eller någon av dess licensgivare ger någon garanti, vare sig
            uttrycklig eller underförstådd, rörande lämplighet för visst ändamål
            eller kapacitet för systemintegration. Inga påståenden utöver de som
            särskilt anges i AV har gjorts med avseende på Tjänsterna, och
            Kunden skall inte förlita sig på några påståenden som inte
            uttryckligen anges i AV.
            <br />
            <br />
            Länkar till webbsidor som inte ägs eller kontrolleras av
            BalancePoint AB som framträder i Tjänsterna eller därmed åtföljande
            webbsidor eller Dokumentation tillhandahålls endast av
            bekvämlighetsskäl. BalancePoint AB är inte ansvarig för sådana
            webbsidor.
            <br />
            <br />
            Om någon del av AV befinns vara ogiltig, helt eller delvis, skall
            detta inte påverka giltigheten av övriga bestämmelser. Den aktuella
            bestämmelsen skall i sådana fall ersättas med en bestämmelse som, så
            långt möjligt, uppnår den ursprungliga bestämmelsens syften.
          </p>
        </div>
        <div>
          <h3 className="">17. Ansvarsbegränsning</h3>
          <p className="">
            BalancePoint AB är inte i något fall ansvarig för innehållet i eller
            äganderätten till Data.
            <br />
            <br />
            BalancePoint AB är inte i något fall ansvarig för några
            instruktioner för Databehandling eller andra åtgärder som utförs av
            Kundens Användare.
            <br />
            <br />
            Om BalancePoint AB hålls ansvarig för betalning av ersättning till
            Kunden som ett resultat av ett brott av någon skyldighet som följer
            av AV, skall sådan ersättning under inga omständigheter innehålla
            ersättning för indirekt förlust eller följdskada, eller skadestånd
            av något slag som följer av, eller är ett resultat av sådant
            avtalsbrott, omfattande men inte uteslutande all förlust av Data,
            förlust av produktion, förlust av intäkt eller vinst, eller tredje
            parts krav eller myndighetsbeslut, även om Kunden har informerats om
            risken för sådana skador. BalancePoint AB ansvar enligt AV är
            begränsat till direkt skada, utom där annat följer av obligatorisk
            lag, såsom vad gäller skador som orsakas av grov vårdslöshet eller
            uppsåt. Alla återbetalningar och ersättningar för direkt skada samt
            direkta förluster och kostnader under varje 12- månaders period
            skall aldrig överstiga ett belopp som motsvarar 12 månaders
            Abonnemangsavgifter för Tjänsterna under samma period. Sådan
            återbetalning eller ersättning kan ej heller överstiga ett
            prisbasbelopp enlig 2 kap. 6§ socialförsäkringsbalken.
            <br />
            <br />
            Varken BalancePoint AB eller Kunden skall vara ansvariga för
            försening eller avbrott i sina åtaganden som orsakas av, eller
            härrör ur, en force majeure-händelse, såsom jordbävning, upplopp,
            arbetskonflikt och andra händelser som på samma sätt är utom
            BalancePoint AB eller Kundens kontroll.
            <br />
            <br />
            För det fall lagstiftning, förordningar eller föreskrifter som rör
            Tjänsterna eller leveransen därav ändras, eller ny lagstiftning,
            förordning eller föreskrift blir gällande efter att Tjänsten har
            tillgängliggjorts på marknaden, som hindrar BalancePoint AB från att
            uppfylla instruktioner från Kunden eller BalancePoint AB
            skyldigheter enligt AV, och/eller som kräver att Tjänsten stängs av,
            helt eller delvis, för en viss tid eller för obestämd tid, skall det
            anses utgöra en force majeure-händelse. BalancePoint AB är inte i
            något fall ansvarig för sådan force majeure-händelse. Kunden blir i
            sådana fall ersatt för i förväg betald Abonnemangsavgift för Tjänst
            som påverkas från den månad som följer avstängningen av Tjänsten med
            anledning av force majeure-händelsen. Utöver detta har Kunden inte
            rätt att framställa ytterligare krav på BalancePoint AB.
            <br />
            <br />
            Även om BalancePoint AB kommer att visa tillbörlig omsorg för säkra
            överföringar av information mellan Kunden och Tjänsterna, medger
            Kunden att Internet är ett öppet system och att BalancePoint AB inte
            kan garantera, och inte heller garanterar, att tredje part inte kan
            komma i besittning av eller ändra Data eller Överföringen.
            BalancePoint AB har inget ansvar för sådant oavsiktligt missbruk,
            avslöjande eller förlust av Data.
            <br />
            <br />
            Oavsett vad som anges i punkten 18 ovan gäller inte
            ansvarsbegräsningen för ersättning enligt punkten 15.
          </p>
        </div>
        <div>
          <h3 className="">18. Avtalande parter och gällande lag</h3>
          <p className="">
            Parternas rättigheter och skyldigheter skall i sin helhet lyda under
            svensk lag. Tvister angående tolkning av AV eller användning av
            Tjänsterna, skall parterna försöka lösa genom uppgörelse i godo. Om
            tvisten inte kan lösas på detta sätt, skall tvisten lösas genom
            skiljedom administrerat vid Stockholms Handelskammares
            Skiljedomsinstitut (Institutet). Överstiger inte tvistemålets värde
            SEK 100 000, skall Institutets Regler för Förenklat Skiljeförfarande
            tillämpas. Överstiger tvisteföremålets värde SEK 100 000, skall
            Skiljedomsregler för Stockholms Handelskammares Skiljedomsinstitut
            tillämpas. Uppgår tvisteföremålets värde till ett belopp mellan SEK
            100 000 och SEK 1 000 000 skall skiljenämnden bestå av en skiljeman.
            Överstiger tvisteföremålet värde SEK 1 000 000 skall skiljenämnden
            bestå av tre skiljemän. Tvisteföremålets värde omfattar kärandens
            yrkande i påkallelseskriften samt eventuella genkäromål i svarandens
            yttrande över påkallelseskriften.
          </p>
        </div>
        <div>
          <h3 className="">19. Felaktigt nyttjande av Tjänsten</h3>
          <p className="">
            Om Kundens felaktiga nyttjande i form av SPAM leder till att
            BalancePoint AB IP-nummer för utskick tillfälligt svartlistas, har
            BalancePoint AB rätt att omedelbart stoppa ytterligare utskick från
            Kunden till dess utredning skett om orsaken till svartlistningen.
            <br />
            <br />
            Om Kundens felaktiga nyttjande även leder till att
            hostingleverantörers IP-nummer för utskick svartlistas så är Kunden
            skyldig att ersätta BalancePoint AB för alla de kostnader som
            uppstått för att byta IP-nummer.
            <br />
            <br />
            Om Kunden fälls i domstol eller får en anmärkning av Konsumentverket
            för felaktigt utnyttjande av Tjänsten har BalancePoint AB rätt att
            omedelbart säga upp detta Avtal utan återbetalning av inbetalda
            engångsavgifter och hyror.
            <br />
            <br />
            BalancePoint AB tar inget ansvar, direkt eller indirekt, vid
            felaktigt nyttjande av Tjänsten och dess moduler enligt denna
            punkten 20.
          </p>
        </div>
        <div>
          <h3 className="">20. Definitioner</h3>
          <p className="">
            <strong> Användare: </strong> En fysisk person, typiskt sett
            anställd hos Kund, som av Kunden får rätt att använda Tjänsten för
            Kundens egna interna verksamhetsändamål.
          </p>
          <p className="">
            <strong> Användarkonto: </strong> Gemensamt begrepp som avser
            Kundens Användare, Data och annan information som rör Kundens
            användning av och tillgång till Tjänsterna.
          </p>
          <p className="">
            <strong> Data: </strong>All data som Överförs av Kunden till eller
            från Tjänsten när Kunden använder Tjänsten i syfte att behandlas av
            Tjänsten.
          </p>
          <p className="">
            <strong> Databehandling: </strong> Alla åtgärder eller serier av
            åtgärder eller annan användning av Data av BalancePoint AB enligt
            Kundens instruktioner eller på annat sätt i avsikt att leverera
            Tjänsterna till Kunden.
          </p>
          <p className="">
            <strong> Kund: </strong> Den juridiska eller fysiska person som
            specificerats på fakturan från BalancePoint AB och som ingår avtal
            med BalancePoint AB baserat på AV.
          </p>
          <p className="">
            <strong> Startdag: </strong> Den dag då leverans av Tjänsterna ska
            påbörjas i enlighet med vad som anges i Avtalet genom att
            BalancePoint AB tillhandahåller inloggningsuppgifter eller andra
            anvisningar för att Kunden ska kunna ta del av Tjänsterna.
          </p>
          <p className="">
            <strong> Tilläggstjänst(-er): </strong> Separata enskilda funktioner
            eller funktionella paket som Kund kan använda mot typiskt sett
            särskild avgift per transaktion.
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
