import React from "react";
import "../LandingPage.css";
import LandingHeader from "../LandingHeader/LandingHeader";
import LandingFooter from "../LandingFooter/LandingFooter";

const Om = () => {
  return (
    <>
      <LandingHeader />
      <div className="destination-page">
        <p className="destination-title">Om Oss</p>
        <div>
          <h3>Produkten</h3>
          <p>
            Janus såg dagens ljus redan på mitten av 1990-talet, då vårt fokus
            var att säkerställa kvaliteten på teknisk drift genom systematisk
            dokumentation av arbetsuppgifter som tillsyn och skötsel via
            streckkoder. Vår vision var att skapa bättre fastigheter genom
            strukturerad uppföljning. Idag, nästan 30 år senare, har vi
            utvecklat en modern tjänst som återknyter till våra ursprungliga
            idéer, men som nu utnyttjar kraften i smarta mobiler och
            molntjänster för att ge användaren ett effektivt verktyg som sparar
            både tid och pengar. Genom att sätta användaren i centrum gör vi det
            möjligt att förvalta fastigheter på ett smartare sätt och skapa
            hållbara resultat.
          </p>
           <h3>Namnet</h3>
          <p>
            Janus var en central figur i den romerska mytologin – en gud för
            dörrar, portar och övergångar, som alltid hade två ansikten; ett som
            blickade bakåt och ett som såg framåt. Han symboliserade förmågan
            att förstå både det förflutna och framtiden, vilket passade perfekt
            för en gud som tog beslut i kritiska övergångar. Namnet "Janus" gav
            även upphov till det engelska ordet "janitor", som betyder
            vaktmästare eller städare – en titel som också symboliserar ansvar
            för underhåll och förvaltning. Vi tycker att detta är ett utmärkt
            namn för en tjänst som hjälper er att fatta välgrundade beslut och
            skapa långsiktiga värden.
          </p>
           <h3>Visionen</h3>
          <p>
            I korthet vill vi att det ska vara enkelt att lyckas med en
            underhållsplan. Det ska vara snabbare och billigare än att använda
            Excel. Framförallt ska det vara lätt att komma igång och får ett
            resultat. Vi vill också erbjuda praktiskt applicerbara AI-funktioner
            för att öka kunskapen om fastighetsunderhåll de möjligheter du som
            användare har att påverka kostnaderna.
          </p>
           <h3>Smart, digital och självförklarande</h3>
          <p>
            Vår SaaS-tjänst är skapad för dig som värdesätter enkelhet,
            effektivitet och självständighet. Vi har designat ett system som är
            så intuitivt och användarvänligt att du kan komma igång direkt –
            utan krånglig installation, tidskrävande utbildningar eller dyra
            konsulttjänster.
          </p>
          <h3>Varför välja oss?</h3>
          <p>
            Användarvänlighet i fokus: Vårt system är självförklarande och
            kräver minimal inlärningstid. Kom igång snabbt och börja se resultat
            direkt. Digital support som levererar: Via vår hemsida får du
            tillgång till omfattande guider, tutorials och svar på vanliga
            frågor – allt tillgängligt dygnet runt. Kostnadseffektiv lösning:
            Genom att eliminera dyra konsulttjänster och telefonsupport kan vi
            erbjuda konkurrenskraftiga abonnemangspriser som passar alla typer
            av företag. Självservice för smidig hantering: Du får full kontroll
            över ditt konto och dina inställningar – precis som det ska vara i
            en modern, digital tjänst. Vi tror på kraften i en digital,
            självbetjänande lösning som ger dig tid att fokusera på det som
            verkligen betyder något – att driva din verksamhet framåt. Välj vår
            SaaS-tjänst och upplev skillnaden med ett system som jobbar smart.
          </p>
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default Om;
